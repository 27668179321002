import React from "react"
import  "../../styles/global.scss"
import { Container, Col, Row } from 'reactstrap';
import Layout from "../../layouts/index"

// change event details here
const EventDetailsPage  = () => (
	<Layout>
		<Container fluid="true" className="event-details-page-title-block">
	        <Row>
	        	<Col className="text-center font-dark ">
	  				<div className="text-bold-500">Blood Test</div>
	  				<div>24 January 2019 | Wednesday</div>
	  				<div className="text-italic">2pm-3pm</div>
	  			</Col>
	 		</Row>
	    </Container>
		<Container fluid="true" className="page-block">
			
			<Row>
				insert event details here

			</Row>

		</Container>
	</Layout>
 )

export default EventDetailsPage
